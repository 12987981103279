<template>
  <a-card class="register-form-card">
    <ValidationObserver ref="observer">
      <a-form
        :label-width="80"
        slot-scope="{ validate, invalid }"
        @submit.prevent="validate().then(confirmAceptTermsAndPolicy)"
      >
        <h2 class="title">{{ labels.title }}</h2>
        <a-row
          type="flex"
          justify="center"
          :style="{ alignItems: 'center' }"
          :gutter="[24, 24]"
        >
          <a-col :md="12" :sm="24" :xs="24">
            <Input
              v-model="form.firstname"
              rules="required"
              vid="firstname"
              :placeholder="labels.form.firstname.placeholder"
              size="large"
              :allowClear="true"
              :label="labels.form.firstname.label"
              @input="onlyAlfa($event, 'firstname')"
            />
          </a-col>
          <a-col :md="12" :sm="24" :xs="24">
            <Input
              v-model="form.lastname"
              rules="required"
              vid="lastname"
              :placeholder="labels.form.lastname.placeholder"
              size="large"
              :allowClear="true"
              :label="labels.form.lastname.label"
            />
          </a-col>
        </a-row>
        <a-row
          type="flex"
          justify="center"
          :style="{ alignItems: 'center' }"
          :gutter="[24, 24]"
        >
          <a-col :md="12" :sm="24" :xs="24">
            <Input
              v-model="form.whatsapp"
              rules="required"
              vid="whatsapp"
              :placeholder="labels.form.whatsapp.placeholder"
              size="large"
              :allowClear="true"
              :label="labels.form.whatsapp.label"
              :maxLength="14"
              v-mask="'(###) ###-####'"
            />
          </a-col>
          <a-col :md="12" :sm="24" :xs="24">
            <Input
              v-model.trim="form.email"
              rules="required|email"
              vid="email"
              :placeholder="labels.form.email.placeholder"
              size="large"
              :allowClear="true"
              :label="labels.form.email.label"
              @input="onlyLowecaseValidEmail($event, 'email')"
              :transform="($event) => $event.replaceAll(' ', '')"
            />
          </a-col>
        </a-row>
        <a-row
          type="flex"
          justify="center"
          :style="{ alignItems: 'center' }"
          :gutter="[24, 24]"
        >
          <a-col :md="12" :sm="24" :xs="24">
            <Input
              type="password"
              v-model="form.password"
              rules="required"
              vid="password"
              :placeholder="labels.form.password.placeholder"
              size="large"
              :allowClear="true"
              :label="labels.form.password.label"
              refs="password"
            />
          </a-col>
          <a-col :md="12" :sm="24" :xs="24">
            <Input
              type="password"
              v-model="form.password_confirmation"
              rules="required|confirmed:password"
              vid="password_confirmation"
              :placeholder="labels.form.passwordConfirm.placeholder"
              size="large"
              :allowClear="true"
              :label="labels.form.passwordConfirm.label"
            />
          </a-col>
        </a-row>
        <a-row
          v-if="hasReferralCode"
          type="flex"
          justify="end"
          :style="{ alignItems: 'center' }"
          :gutter="[24, 24]"
        >
          <a-col :md="12" :sm="24" :xs="24">
            <Input
              v-model.trim="form.referral_code"
              vid="referral_code"
              :placeholder="labels.form.referralCode.placeholder"
              size="large"
              :allowClear="true"
              :label="labels.form.referralCode.label"
              :disabled="hasReferralCode"
              :readOnly="hasReferralCode"
              :maxLength="8"
              @input="onlyAlfaNumbericUpperCase($event, 'referral_code')"
            />
          </a-col>
        </a-row>
        <a-row
          v-if="false"
          type="flex"
          justify="center"
          :style="{ alignItems: 'center' }"
          :gutter="[24, 24]"
        >
          <a-col v-if="false" :md="24" :sm="24" :xs="24">
            <RecaptchaCheckbox
              v-model="form.recaptchaToken"
              vid="recaptcha"
              :label="'recaptcha'"
              :onChange="onChangeRecaptcha"
            />
          </a-col>
          <a-col :md="24" :sm="24" :xs="24">
            <a-form-item>
              <a-checkbox v-model="form.acept_terms">
                {{ labels.form.agreeWidth }}
                <router-link
                  class="link"
                  :to="{ name: 'privacy-policies' }"
                  target="_blank"
                  >{{ labels.form.privacityPolicy }}</router-link
                >,
                <router-link
                  class="link"
                  :to="{ name: 'terms-and-conditions' }"
                  target="_blank"
                  >{{ labels.form.terms }}</router-link
                >
              </a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row
          type="flex"
          justify="center"
          :style="{ alignItems: 'center' }"
          :gutter="[24, 24]"
        >
          <a-col :span="24">
            <!-- Subscribe button -->
            <a-form-item style="text-align: right">
              <a-button
                type="default"
                html-type="submit"
                :loading="submitting"
                :disabled="submitting || invalid"
                size="large"
                class="btn-send"
                @click.prevent="validate().then(confirmAceptTermsAndPolicy)"
              >
                <span>{{ labels.form.submit }}</span>
              </a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </ValidationObserver>
  </a-card>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import { mapActions, mapState } from "vuex";
import _ from "lodash";
import { Modal } from "ant-design-vue";
import Input from "@/components/core/VeeValidateForm/Input";
import RecaptchaCheckbox from "@/components/core/VeeValidateForm/RecaptchaCheckbox";
import labels from "@/utils/labels";
import EventBus, { ListEvents } from "../event";
import { CRAUE_CONFIG } from "@/utils/utils";
const initialState = {
  firstname: "",
  lastname: "",
  email: "",
  referral_code: "",
  whatsapp: "",
  password: "",
  password_confirmation: "",
  province: undefined,
  address: "",
  city: "",
  acept_terms: false,
  not_robot: false,
  // recaptchaToken: "",
};
export default {
  name: "RegisterForm",
  components: {
    ValidationObserver,
    Input,
    RecaptchaCheckbox,
  },
  data() {
    return {
      form: _.cloneDeep(initialState),
      loading: false,
      submitting: false,
      verifyPhone: false,
      labels: {
        ...labels.registerForm,
        appName: labels.appName,
        unknownServerError: labels.unknownServerError,
      },
    };
  },
  created() {
    if (this.hasReferralCode) {
      this.form.referral_code = this.$route.query.referralId;
    }
  },
  computed: {
    ...mapState(["redirectRoute"]),
    hasReferralCode() {
      return !!this.$route.query?.referralId;
    },
  },
  methods: {
    ...mapActions(["fetchProvincesSelect"]),
    ...mapActions("clientAuth", ["handleRegister", "setFromRegister"]),
    ...mapActions("systemConfig", ["getCraueConfigByNameClient"]),
    async getSystemConfig() {
      try {
        this.loading = true;
        const { data: verifyPhone } = await this.getCraueConfigByNameClient(
          CRAUE_CONFIG.VAR_VERIFY_PHONE
        );
        this.verifyPhone = verifyPhone?.value === "1";
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    confirmAceptTermsAndPolicy() {
      const policyURI = this.$router.resolve({ name: "privacy-policies" }).href;
      const termsURI = this.$router.resolve({
        name: "terms-and-conditions",
      }).href;
      this.$confirm({
        title: this.labels.confirm.title,
        centered: false,
        class: "confirm-policy-and-condition-modal",
        // JSX support
        content: (
          <div style="display:block;width:100%;">
            {this.labels.form.agreeWidth}{" "}
            <a class="link" href={policyURI} target="_blank">
              {this.labels.form.privacityPolicy}
            </a>
            ,{" "}
            <a class="link" href={termsURI} target="_blank">
              {this.labels.form.terms}
            </a>
          </div>
        ),
        cancelText: this.labels.confirm.cancelText,
        okText: this.labels.confirm.okText,
        okType: "primary",
        autoFocusButton: "ok",
        onCancel: async () => {
          this.form.acept_terms = false;
        },
        onOk: async () => {
          this.form.acept_terms = true;
          await this.handleSubmit();
        },
      });
    },
    goToLink(type) {
      const route = this.$router.resolve({
        name: "transaction-request",
        query: { type },
      });
      if (route.href !== this.$route.fullPath) this.$router.push(route.href);
    },
    async handleSubmit() {
      // if (!!this.form.recaptchaToken === false) {
      //   this.$message.error(this.labels.form.notRobot);
      //   return;
      // }

      if (!!this.form.acept_terms === false) {
        this.$message.error(this.labels.form.aceptTerms);
        return;
      }

      try {
        this.submitting = true;
        await this.handleRegister(this.form);
        await this.getSystemConfig(); // This need to be run after logged in
        this.setFromRegister(true);
        this.$nextTick(() => {
          this.$router.push({
            name: this.verifyPhone ? "verify-phone" : "welcome",
          });
        });
      } catch (error) {
        if (error.isAxiosError && error.response?.status === 401) {
          this.$error({
            title: this.labels.error,
            content:
              error.response?.data?.message ||
              error.message ||
              this.labels.form.errorMessage,
            centered: true,
          });
        } else if (error.isAxiosError && error.response.status === 400) {
          Modal.destroyAll();
          this.$error({
            title: `${error.response?.data?.message || error.message} ${
              error.response.status
            }`,
            centered: false,
            class: "error-modal",
            okType: "primary",
            autoFocusButton: "ok",
            okText: this.labels.accept,
            content: (h) =>
              h(
                "ul",
                { class: "error-list" },
                error.response?.data?.additional_errors?.map((error, index) =>
                  h("li", { class: "error-list-item", key: index }, error)
                )
              ),
          });
        } else {
          Modal.destroyAll();
          this.$notification["error"]({
            message: `${this.labels.error} ${
              error?.code || error?.response?.status || 500
            }`,
            description:
              error?.message ||
              error?.response?.data?.message ||
              this.labels.unknownServerError,
          });
        }
        EventBus.$emit(ListEvents.reset_recaptcha);
      } finally {
        this.submitting = false;
      }
    },
    async onlyAlfa(value = "", field) {
      this.form[field] = await value.replace(/[^a-zA-Z\s]/g, "");
    },
    async onlyAlfaNumbericUpperCase(value = "", field) {
      this.form[field] = await value
        .replace(/[^a-zA-Z0-9\s]/g, "")
        .toUpperCase();
    },
    async onlyLowecaseValidEmail(value = "", field) {
      this.form[field] = await value
        .replace(/[^a-zA-Z0-9_\-@.]/g, "")
        .replaceAll(" ", "")
        .toLowerCase();
    },
    onChangeRecaptcha(value) {
      this.form.not_robot = !!value;
    },
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variable";
.register-form-card {
  background-color: color(c-secondary);
  border-color: color(c-secondary);
  padding: 3.125rem;
  border-radius: 10px;
  @media screen and (max-width: $screen-mobile) {
    padding: 2.125rem 1rem;
  }
  .title {
    font-family: "Exo 2", sans-serif;
    color: color(--white);
    font-weight: 400;
    margin: 0;
    line-height: 1.3;
    word-break: break-word;
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  .ant-card-body {
    padding: 0;
  }
  .ant-form label {
    font-size: 0.875rem;
    color: color(--white);
    font-family: "Roboto", sans-serif;
  }
  .ant-input {
    background-color: color(c-primary);
    color: color(--white);
    &:active,
    &:focus,
    &:hover {
      border-color: color(c-hover);
    }
  }
  .ant-input-affix-wrapper {
    .ant-input:not(:first-child) {
      padding-left: 2.8125rem;
    }
    .ant-input-prefix {
      color: color(--white);
      font-size: 1.375rem;
      font-family: "Roboto", sans-serif;
    }
    .ant-input-clear-icon,
    .ant-input-textarea-clear-icon,
    .ant-input-password-icon {
      color: color(--white);
    }
  }

  .has-error .ant-input-affix-wrapper .ant-input,
  .has-error .ant-input-affix-wrapper .ant-input:hover {
    background-color: color(c-primary);
  }

  .btn-send {
    color: color(c-primary);
    background-color: color(c-hover);
    border-color: color(c-hover);
    height: 51px;
    padding: 0 1.75rem;
    @media screen and (max-width: 596px) {
      width: 100% !important;
    }
  }

  .link {
    color: color(c-hover);
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 400;
    margin-left: 5px;
  }

  .ant-select {
    .ant-select-selection--single {
      color: color(--white);
      background-color: color(c-primary);
      border: 1px solid #d9d9d9;
      &:active,
      &:focus,
      &:hover {
        border-color: color(c-hover);
      }
      .anticon-down {
        color: color(--white);
      }
    }
  }
}

input:-internal-autofill-selected {
  background-color: color(c-primary) !important;
  border-color: color(c-hover) !important;
  color: black !important;
}

.custom-select {
  background-color: color(c-primary) !important;
  .ant-select-dropdown-menu-item {
    color: color(--white) !important;
    &:hover {
      background-color: color(c-secondary) !important;
    }
    &-active {
      background-color: color(c-secondary) !important;
    }
  }
}
.error-modal,
.confirm-policy-and-condition-modal {
  .ant-modal-content {
    background-color: color(c-secondary) !important;
    box-shadow: 0 1px 3px 3px color(c-hover-wait);
    .ant-modal-confirm-title {
      color: color(--white) !important;
    }
    .ant-modal-confirm-content {
      color: color(--white) !important;
      .link {
        color: color(c-hover);
        font-family: "Roboto", sans-serif;
        text-decoration: none;
        font-size: 1rem;
        font-weight: 500;
        margin-left: 5px;
        &:hover {
          text-decoration: underline;
        }
      }
      .error-list-item {
        font-size: 0.95rem;
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .ant-modal-confirm-btns {
      .ant-btn {
        background-color: color(c-secondary);
        color: color(c-hover) !important;
        border-color: color(c-hover);
        &:hover,
        &:focus,
        &:active {
          background-color: color(c-secondary);
          color: color(c-hover) !important;
          border-color: color(c-hover);
        }
        &.ant-btn-primary {
          background-color: color(c-hover);
          color: color(c-primary) !important;
          border-color: color(c-hover);
          &:hover {
            background-color: color(c-hover);
            color: color(c-primary) !important;
            border-color: color(c-hover);
          }
        }
      }
    }
  }
}
</style>
