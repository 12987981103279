<template>
  <a-layout class="register">
    <TitleBreadcrumb />
    <div class="content-base container">
      <a-row type="flex" justify="center" :gutter="[16, 16]">
        <a-col :md="20">
          <RegisterForm />
        </a-col>
      </a-row>
    </div>
  </a-layout>
</template>
<script>
import TitleBreadcrumb from "@/components/layout/client/TitleBreadcrumb.vue";
import RegisterForm from "@/components/RegisterForm.vue";
import store from "@/store";
import loadingMixin from "@/mixins/loadingMixin";
import { pageTitle } from "../../utils/utils";
import labels from "@/utils/labels";
export default {
  components: { TitleBreadcrumb, RegisterForm },
  name: "Register",
  mixins: [loadingMixin],
  metaInfo: {
    title: pageTitle(labels.registerForm.subtitle),
    meta: [
      {
        name: "title",
        content: labels.appName,
      },
      {
        name: "description",
        content: labels.registerForm.subtitle,
      },
    ],
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state?.clientAuth?.authenticated) next();
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variable";
.register {
  max-width: 100% !important;
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  .content-base {
    padding-top: 60px;
    @media screen and (max-width: $screen-mobile) {
      padding-top: 50px;
      max-width: 100% !important;
    }
  }
}
</style>
